import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
//import Table, {TableBody, TableCell, TableHead, TableRow} from '@material-ui/core/Table';
//import Paper from '@material-ui/core/Paper';
import {TextField, Typography, Button, FormLabel,Toolbar,IconButton} from '@material-ui/core';
//import IconButton from '@material-ui/core/IconButton';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import {FormGroup} from '@material-ui/core';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';
//import Dialog from '@material-ui/core/Dialog';
//import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import Media from './media';


import ImageControl from './shares/react_image_control';

import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';

// 

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';


function Transition(props) {
  return <Slide direction="up" {...props} />;
}


class AlbumDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      album: {
        images: [],
        date: (new Date()).toString(),
        cover: '',
        titleVN: '',
        summaryVN: '',
        titleEN: '',
        summaryEN: '',
        urlVN: '',
        urlEN: '',
      },
      error: {
        vn: false,
        en: false,
        titleVN_error: false,
        titleEN_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false,
      openMedia: false,
      files: [],
      source: null,
      oldIndex: null,
      newIndex: null
    }
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('albums',params.id, function(res) {
          if (res.status == 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                //console.log(item[property]);
                data[property] = item[property];
              }
            }
            data._id = item._id;
            if(data.urlVN===undefined){
              data.urlVN = '';
            }
            if(data.urlEN===undefined){
              data.urlEN = '';
            }
            //if(data.images.length===0)data.images.push('');
            $this.setState({album: data},function(){
              //console.log($this.state.banner);
            });
          }
        });
      });
    }
  }
  onEditorStateChange(field,value){
    var album = this.state.album;
    album[field] = value;

    this.setState({album:album});
  }
  addMoreItem(){
    var album = this.state.album,
        data = album.images,
        l = data.length;
    data.push({src:'',desc:''});
    album.images = data;
    this.setState({album:album},function(){

    });
  }
  addMoreItems(){
    //console.log('click ...');
    var $this = this,
        album = this.state.album,
        data = album.images;

    this.state.files.map(x => {
      data.push({src:x.fullpath,desc:''});
    });
    album.images = data;
    this.setState({album:album},function(){
      $this.handleCloseMedia();
    });
  }
  storeSeleted(items){
    this.setState({files:items});
  }
  openMediaDialog = (e) => {
    this.setState({ openMedia: true });
  }
  removeItem(index){
    //console.log(index);
    var album = this.state.album,
        data = album.images,
        l = data.length;
        if(l>0){
          data.splice(index,1);
          album.images = data;
          this.setState({album:album},function(){

          });
        }
  }
  handleImageChange(index,value){
    //console.log(value);
    var $this = this,
        album = this.state.album;
    album.images[index].src = value;
    this.setState({album:album});
  }
  handleIDChange(index,e){
    var $this = this,
        album = this.state.album;
    album.images[index].desc = e.target.value;
    this.setState({album:album});
  }
  handleFieldChange(e) {
    var $this = this,
        album = this.state.album;
    album[e.target.id] = e.target.value;
    this.setState({album:album});
  }
  handleCover(value) {
    var $this = this,
        album = this.state.album;
    album.cover = value;
    this.setState({album:album});
  }
  handleValidate(e,flag) {
    var $this = this,
        album = this.state.album,
        obj = this.state.error;

    album[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() == '' || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    if(e.target.id==='titleVN'){
      var urlVN = Utils.getFriendlyURL(e.target.value);
      album.urlVN = urlVN;
    }
    if(e.target.id==='titleEN'){
      var urlEN = Utils.getFriendlyURL(e.target.value);
      album.urlEN = urlEN;
    }

    this.setState({album: album, error:obj}, () => {
    });
  }
  selectImg(index,value){
    var $this = this,
        album = this.state.album;
    album.images[index] = value;
    this.setState({album:album},function(){
      $this.handleClose();
    });
  }
  _save(){
    var $this = this;
    var data = this.state.album;
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('album',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/album/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('album',data, function(res) {
        if (res.status == 'success') {
          $this.context.router.history.push('/album/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj['titleVN_error'] = this.state.album.titleVN === '';
          obj['vn'] = obj['titleVN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj['titleEN_error'] = this.state.album.titleEN === '';
          obj['en'] = obj['titleEN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push('/album/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  handleCloseMedia = () => {
    this.setState({ openMedia: false, files:[] });
  }
  handleDragStart(e,item,index){
    var event = e;
    //event.dataTransfer.setData("text/plain", event.target.innerHTML);
    event.dataTransfer.effectAllowed = "move";
    this.setState({
      source: event.target,
      oldIndex: index
    });

  }
  handleDragOver(e){
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }
  handleDragEnd(e){
    //console.log(e);
  }
  handleDrop(e,item,index){
    //drop
    e.preventDefault();
    e.stopPropagation();
    var $this = this,
        album = this.state.album,
        data = this.state.album.images,
        old = data[$this.state.oldIndex];

    data.splice($this.state.oldIndex,1);
    data.splice(index,0,old);
    //console.log(data);
    // for(var i=0,len=data.length;i<len;i++){
    //   data[i].order = parseInt($this.state.activeStep*config.itemPerPage)+i
    // }
    album.images = data;

    this.setState({
      album:album
    },function(){
      console.log(album.images);
    });
  }
  handleCopy(){
    var data = this.state.album;
    data.titleEN = data.titleVN;
    //data.titleKR = data.titleVN;
    data.summaryEN = data.summaryVN;
    //data.summaryKR = data.summaryVN;
    this.setState({
      album: data
    })
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const album = this.state.album;
    const editorConfigBasic = Utils.setupBasicEditor();

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa Album hình ảnh</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <Button variant="contained" color="secondary" aria-label="copy" onClick={()=>this.handleCopy()}>Copy to [EN]</Button>
            <TextField id="titleVN" label={language.VN.name} className={classes.textField} value={album.titleVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.titleVN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="urlVN" label="Link [SEO]" className={classes.textField} value={album.urlVN} disabled={true} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="date" label="Thời gian" className={classes.textField} value={album.date} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <ImageControl label="Hình bìa" value={album.cover} handleChange={(value) => this.handleCover(value)}></ImageControl>
            <Button variant="contained" color="primary" aria-label="add" size="small" onClick={this.openMediaDialog}>
            Thêm hình(s)<AddIcon /></Button>
            <div className="item-image-grid">
            {
              album.images.map((x,i)=>{
                return (
                  <div key={i} className={classNames('item-image-wrapper',classes.drag_item)} draggable={true} onDragStart={(e)=>this.handleDragStart(e,x,i)} onDragOver={(e)=>this.handleDragOver(e)} onDragEnd={(e)=>this.handleDragEnd(e)} onDrop={(e)=>this.handleDrop(e,x,i)}>
                    <IconButton color="secondary" aria-label="delete" size="small" onClick={()=>this.removeItem(i)}>
                      <DeleteIcon />
                    </IconButton>
                    <ImageControl value={x.src} handleChange={(value) => this.handleImageChange(i,value)}></ImageControl>
                    <TextField label="Tiêu đề" className={classes.textField} value={x.desc} onChange={(event) => this.handleIDChange(i,event)} margin="normal" InputLabelProps={{
                      shrink: true
                    }}/>
                  </div>
                )
              })
            }
            </div>
            <FormLabel className={classes.label_shrink}>{language.VN.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={this.state.album.summaryVN}
              onModelChange={(value)=>this.onEditorStateChange('summaryVN',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleEN" label={language.EN.name} className={classes.textField} value={album.titleEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.titleEN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="urlEN" label="Link [SEO]" className={classes.textField} value={album.urlEN} disabled={true} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormLabel className={classes.label_shrink}>{language.EN.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={album.summaryEN}
              onModelChange={(value)=>this.onEditorStateChange('summaryEN',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        <Dialog
          fullScreen
          open={this.state.openMedia}
          onClose={this.handleCloseMedia}
          TransitionComponent={Transition}
        >
          <div className={classes.dialogMedia}>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={this.handleCloseMedia}>{language.VN.btnCancel}</Button>
              <Button variant="contained" onClick={()=>this.addMoreItems()} color="primary">{language.VN.btnSave}</Button>
            </FormGroup>
            <Media onMultiSelectChange={(items)=>this.storeSeleted(items)}/>
          </div>
        </Dialog>
      </div>
    )
  }
}

AlbumDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
AlbumDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(AlbumDetail);
