import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Fab, Toolbar, TextField } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';

import ReactTable from './shares/react_table';
import ReactLazyCardMedia from './shares/react_lazy_card';


import theme, { styles } from '../layout/theme';
import Utils from '../api/api';

export class AlbumList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      searchClick: false
    }
  }

  handleSearch(e) {
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }

  handleAdd() {
    this.context.router.history.push('/album/add');
  }

  render() {
    const $this = this;
    const classes = $this.props.classes;

    var source = {
      thead: [{ title: 'Tiêu đề', value: 'title' }, { title: 'Id/SEO URL', value: 'url' }, { title: 'Hình', value: 'photo' }, { title: 'Ngày', value: 'date' }],
      tbody: function (n) {
        return ({
          _id: n._id,
          title: n.titleVN,
          url: <div>{n._id}<br /><br />{n.urlVN}</div>,
          photo: <div className={classes.thumbnailWrapper}><ReactLazyCardMedia className={classes.thumbnail} image={n.cover !== '' && n.cover !== undefined ? Utils.fixImagePath(n.cover) : Utils.fixImagePath('/data/upload/images/no_photo.png',{width:100,height:60})} title={n.titleVN} /></div>,
          date: Utils.getBeautyDate(n.date, 'vn')
        })
      }
    };

    return (
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục Album hình ảnh</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} margin="normal" InputLabelProps={{ shrink: true }} />
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e) => this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={() => this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiSingle='album' apiTable='albums' apiDuplicate={true} router={this.context.router} dataMap={source} showCheckBox={false} keyword={this.state.keyword} isSearch={this.state.searchClick}>
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

AlbumList.propTypes = {
  classes: PropTypes.object.isRequired,
};
AlbumList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(AlbumList);